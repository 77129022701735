import React from "react";

const Content_Header = () => {
  return (
    <>
      <div className="content_results_minih_date">DATE</div>
      <div className="content_results_minih_label">LOTO</div>
      <div className="content_results_minih_nums">NUMÉROS GAGNANTS</div>
    </>
  );
};

export default Content_Header;
