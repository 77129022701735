import React, { useContext } from "react";
import { AppContext } from "../../App";
import Content_Header from "./Content_Header";
import Content_List from "./Content_List";

const Content = () => {
  const consumer = useContext(AppContext);

  return (
    <div className="content_results_root">
      <div className="content_results_miniheader">
        <Content_Header />
      </div>
      <div className="content_results_list">
        <Content_List lotteries={consumer.content[0]} />
      </div>
    </div>
  );
};

export default Content;
