export const fromStringToArray = (string, separator) => {
  let array = string.split(separator);
  let xx = [];
  for (let i = 0; i < array.length; i++) {
    xx.push(parseInt(array[i]));
  }
  xx.sort((a, b) => a - b);
  return xx;
};
export const textFormatter = (string, length) => {
  if (string.length < length) {
    return string;
  } else {
    return string.substr(0, length - 2) + "...";
  }
};
export const createFlag = (countryCode) => {
  var toReturn = "FlagsAssets/" + countryCode?.toLowerCase() + ".png";
  return toReturn;
};
export const getCurrentDaysArr = (language) => {
  const d = new Date();
  let shortDaysArr = [];
  let dayNmonth = [];
  for (let i = 0; i < 8; i++) {
    let objShort =
      d.toLocaleString(language, { weekday: "short" }).charAt(0).toUpperCase() +
      d.toLocaleString(language, { weekday: "short" }).slice(1);
    // if (objShort.length > 3) objShort = objShort.slice(0, -1);
    shortDaysArr.push(objShort);
    let obj = {
      day: d.getDate() > 9 ? d.getDate() : "0" + d.getDate(),
      month: d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1),
    };
    d.setDate(d.getDate() - 1);
    dayNmonth.push(obj);
  }
  let toReturn = {
    shortDays: shortDaysArr,
    dayNmonth: dayNmonth,
  };
  return toReturn;
};
export const convertUtcToSingleRowTime = (utcString) => {
  let ddd = new Date(utcString);
  let day =
    ddd.toLocaleString("FR-fr", { weekday: "short" }).charAt(0).toUpperCase() +
    ddd.toLocaleString("FR-fr", { weekday: "short" }).slice(1);
  // let toLocaleTime = ddd.toLocaleTimeString();
  // console.log(toLocaleTime);
  // let hour = toLocaleTime.includes("AM")
  //   ? toLocaleTime.length === 11
  //     ? toLocaleTime.slice(0, 5)
  //     : "0" + toLocaleTime.slice(0, 4)
  //   : toLocaleTime.length === 11
  //   ? (parseInt(toLocaleTime.slice(0, 2)) + 12).toString() +
  //     toLocaleTime.slice(2, 5)
  //   : (parseInt(toLocaleTime.slice(0, 1)) + 12).toString() +
  //     toLocaleTime.slice(1, 4);
  // let x = ddd.getTimezoneOffset();
  // // let xHours = ddd.getHours() + x / 60;
  // // let hour = xHours > 9 ? xHours : "0" + xHours;
  // let mins = ddd.getMinutes() > 9 ? ddd.getMinutes() : "0" + ddd.getMinutes();
  let newStuff = new Date(ddd.getTime() + ddd.getTimezoneOffset() * 60 * 1000);
  var offset = ddd.getTimezoneOffset() / 60;
  var hours = ddd.getHours();
  newStuff.setHours(hours - offset);
  let yy =
    newStuff.getMinutes() < 10
      ? "0" + newStuff.getMinutes()
      : newStuff.getMinutes();
  let zz = hours < 10 ? "0" + hours : hours;
  return day + " " + zz + ":" + yy;
  // return day + " " + newStuff.getHours() + ":" + newStuff.getMinutes();
};
export const convertUtcToTicketTimeWithMins = (utcString) => {
  let ddd = new Date(utcString);
  let day =
    ddd.toLocaleString("FR-fr", { weekday: "short" }).charAt(0).toUpperCase() +
    ddd.toLocaleString("FR-fr", { weekday: "short" }).slice(1);
  let dayNum = ddd.getDate() > 9 ? ddd.getDate() : "0" + ddd.getDate();
  let month =
    ddd.getMonth() + 1 > 9 ? ddd.getMonth() + 1 : "0" + (ddd.getMonth() + 1);
  let hour = ddd.getHours() > 9 ? ddd.getHours() : "0" + ddd.getHours();
  let mins = ddd.getMinutes() > 9 ? ddd.getMinutes() : "0" + ddd.getMinutes();
  return day + " " + dayNum + "." + month + " - " + hour + ":" + mins;
};
