import React, { useContext } from "react";
import { GetContentByLottery } from "../../APIManager/APIManager";
import { AppContext } from "../../App";
import { createFlag, textFormatter } from "../../Helpers/Helpers";

const TopIndex_SingleItem = (props) => {
  const consumer = useContext(AppContext);

  const Fetcher = () => {
    GetContentByLottery(
      consumer.token[0],
      consumer.dateSelector[0].indexOf(1),
      props.id,
      consumer.content[1]
    );
  };

  return (
    <div
      className="top_idx_single_element"
      key={props.keyz}
      onClick={() => {
        Fetcher();
      }}
    >
      <div className="top_idx_logo">
        <img
          src={createFlag(props.countryCode)}
          width="20px"
          height="20px"
          className="top_idx_logo_i"
        />
      </div>
      <div className="top_idx_span">{textFormatter(props.lotteryName, 23)}</div>
      <div className="top_idx_balls_logo">
        <div className="top_idx_guess">{props.drawBalls}</div>
        <div className="top_idx_total">{props.ballsCount}</div>
      </div>
    </div>
  );
};

export default TopIndex_SingleItem;
