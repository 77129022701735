import React from "react";

const SideMenuMobile = (props) => {
  return (
    <div className="side_mobile_root">
      <div className="s_side_mobile_results">
        <div
          onClick={() => {
            props.HandleMobileMenu();
          }}
        >
          <div className="mwrap">
            <img
              src="./mresults.png"
              height="27px"
              width="27px"
              className="m_icon"
            />
            <span className="m_label">MENU</span>
          </div>
        </div>
      </div>
      <div
        className="s_side_mobile_date"
        onClick={() => {
          props.HandleMobileDate();
        }}
      >
        <div className="mwrap">
          <img
            src="./mdate.png"
            height="30px"
            width="30px"
            className="m_icon"
          />
          <span className="m_label">TODAY</span>
        </div>
      </div>
      <div
        className="s_side_mobile_tickets"
        onClick={() => {
          props.HandleMobileVerify();
        }}
      >
        <div>
          <div className="mwrap">
            <img
              src="./mtickets.png"
              height="27px"
              width="27px"
              className="m_icon"
            />
            <span className="m_label">VERIFY</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenuMobile;
