import "./App.css";
import Results from "./Results/Results";
import { createContext, useEffect, useState } from "react";
import {
  GetContentByCountry,
  GetContentBySystem,
  GetDailyLotteries,
  GetDailySystems,
  GetTopIndex,
  InitToken,
} from "./APIManager/APIManager";

export const AppContext = createContext(null);

function App() {
  const [dateSelector, setDateSelector] = useState([1, 0, 0, 0, 0, 0, 0]);

  const [topIndex, setTopIndex] = useState(null); //Used to store top index lotteries, when app mounts this will populate
  const [dailyLotteries, setDailyLotteries] = useState(null); //Used to store all lotteries for the sidemenu, when app mounts this will populate
  const [dailySystems, setDailySystems] = useState(null); //Used to store all systems for lotteries for sideMenu, when app mounts this will populate
  const [buildSystemFilter, setBuildSystemFilter] = useState([]); // Used to store all the checked values for the system selection
  const [buildSystemFilterNames, setBuildSystemFilterNames] = useState([]); //Used to store the names of all systems checked values
  const [content, setContent] = useState(null); //Used to store the displayed content, when app mounts this will populate with ALL LOTTERIES

  const [genericTabs, setGenericTabs] = useState([1, 0]); //Used to keep track of what tab in the menu is selected

  //Those 3 hooks bellow are used for the infinite scrolling component.
  const [count, setCount] = useState({
    prev: 0,
    next: 50,
  }); //Used to store the pagination indexes of infinite scroll (every time there are rendered 50 results a time)
  const [hasMore, setHasMore] = useState(true); // Used to check if the infinite scrolling component has more results to display (the check is done increasing the index by 50)
  const [current, setCurrent] = useState(null); //Used to store the current 'value' of pagination in infinite component (ex: from 450 to 500 the current will keep results from 450index in all results array to 500)

  const [token, setToken] = useState(null);

  const getMoreData = () => {
    if (current.length === content.length) {
      setHasMore(false);
      setCount({ prev: 50, next: 100 });
      return;
    }
    setTimeout(() => {
      setCurrent(current.concat(content.slice(count.prev + 1, count.next + 1)));
    }, 500);
    setCount((prevState) => ({
      prev: prevState.prev + 50,
      next: prevState.next + 50,
    }));
  }; // Funtion used to load more results in infinite loading component

  const initMoreData = () => {
    content != null && setCurrent(content.slice(count.prev, count.next));
  }; // Function to initialize the current view of infinite loading

  useEffect(() => {
    if (content != null && content.length < 100) {
      setCount({ prev: 0, next: 50 });
      setHasMore(false);
      setCurrent(content);
    } else if (content != null && content.length >= 100) {
      setCount({ prev: 50, next: 100 });
      setHasMore(true);
      setCurrent(content.slice(0, 50));
    }
  }, [content]); // When content changes, this useefect will run

  useEffect(() => {
    if (token != null) {
      GetTopIndex(token, 0, setTopIndex); // get top index lotteries
      GetDailyLotteries(token, 0, setDailyLotteries); //get all lotteries
      GetDailySystems(token, 0, setDailySystems); // get all systems
      GetContentByCountry(token, 0, "", setContent); //get all lotteries details
    }
  }, [token]); // When App did mount, execute the code within this useeffect

  useEffect(() => {
    GetTopIndex(token, dateSelector.indexOf(1), setTopIndex);
    GetDailyLotteries(token, dateSelector.indexOf(1), setDailyLotteries);
    GetDailySystems(token, dateSelector.indexOf(1), setDailySystems);
    GetContentByCountry(token, dateSelector.indexOf(1), "", setContent);
  }, [dateSelector]); // When the date is changed, refetch values for the menu and the content(for all lotteries)

  useEffect(() => {
    if (buildSystemFilter.length > 0) {
      let str = buildSystemFilter.join(",");
      GetContentBySystem(
        token,
        dateSelector.indexOf(1),
        str,
        setContent
      );
    }
  }, [buildSystemFilter]); // When a new system is selected in the menu, fetch the coresponding lotteries

  //Values stored in context to be easly accesible from anywhere
  const contextValues = {
    dateSelector: [dateSelector, setDateSelector],
    topIndex: [topIndex, setTopIndex],
    genericTabs: [genericTabs, setGenericTabs],
    dailyLotteries: [dailyLotteries, setDailyLotteries],
    dailySystems: [dailySystems, setDailySystems],
    buildSystemFilter: [buildSystemFilter, setBuildSystemFilter],
    buildSystemFilterNames: [buildSystemFilterNames, setBuildSystemFilterNames],
    content: [content, setContent],
    token: [token, setToken],
    count: [count, setCount],
    hasMore: [hasMore, setHasMore],
    current: [current, setCurrent],
    initMoreData,
    getMoreData,
  };

  useEffect(() => {
    InitToken(setToken);
  }, []);

  //Render
  return (
    token && (
      <AppContext.Provider value={contextValues}>
        <div className="blanker"></div>
        <div className="App">{content && <Results />}</div>
      </AppContext.Provider>
    )
  );
}

export default App;
