import React, { useState } from "react";
import Content from "./MISCS/Content";
import SideMenu from "./MISCS/SideMenu";
import SideMenuMobile from "./MISCS/SideMenuMobile";
import Ticket_Verification from "./MISCS/Ticket_Verification";
import "./Results.css";
import DateSelector from "./Selector/DateSelector";

const Results = (props) => {
  const [toggleMoblieMenu, setToggleMobileMenu] = useState(false);
  const HandleMobileMenu = () => setToggleMobileMenu(!toggleMoblieMenu);

  const [toggleMobileDate, setToggleMobileDate] = useState(false);
  const HandleMobileDate = () => setToggleMobileDate(!toggleMobileDate);

  const [toggleMobileVerify, setToggleMobileVerify] = useState(false);
  const HandleMobileVerify = () => setToggleMobileVerify(!toggleMobileVerify);

  return (
    <div className="results_root">
      <div className="results_side_menu_mobile">
        <SideMenuMobile
          HandleMobileMenu={HandleMobileMenu}
          HandleMobileDate={HandleMobileDate}
          HandleMobileVerify={HandleMobileVerify}
        />
      </div>
      <div className="results_side_menu">
        <SideMenu />
      </div>
      <div className="results_date_selector">
        <DateSelector />
      </div>
      <div className="results_content">
        <Content />
      </div>
      {toggleMoblieMenu && (
        <div
          className="side_mobile_menu"
          onClick={(e) => {
            if (e.target === e.currentTarget) setToggleMobileMenu(false);
          }}
        >
          <div className="side_mobile_wrapper">
            <SideMenu mobile={true} />
          </div>
        </div>
      )}
      {toggleMobileDate && (
        <div
          className="side_mobile_dateselector"
          onClick={(e) => {
            if (e.target === e.currentTarget) setToggleMobileDate(false);
          }}
        >
          <div className="side_mobile_date_wrapper">
            <DateSelector mobile={true} />
          </div>
        </div>
      )}
      {toggleMobileVerify && (
        <div
          className="side_mobile_verify"
          onClick={(e) => {
            if (e.target === e.currentTarget) setToggleMobileVerify(false);
          }}
        >
          <div className="side_mobile_verify_wrapper">
            <Ticket_Verification
              HandleMobileVerify={HandleMobileVerify}
              mobile={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Results;
