import axios from "axios";
let tkn = localStorage.getItem("tkn");

export const InitToken = async (setter) => {
  const params = new URLSearchParams();
  params.append("client_id", "syncweb_client");
  params.append(
    "client_secret",
    "AeQoWBC8zPBX2SlbEIGx7aO65nglXzX1kdLPEDYIYGRRH2jyqlsra1TB80WaYgGlIG4Af6Qb1aHinxek"
  );
  params.append("scope", "anonymous");
  params.append("grant_type", "client_credentials");

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  await axios
    .post("https://authorization-srv.zarlab.dev/connect/token", params, config)
    .then((response) => setter(response.data.access_token));
};

export const GetTopIndex = async (tkn, daySelected, setter) => {
  const config = {
    headers: { Authorization: `Bearer ${tkn}`, "Accept-Language": "fr-FR" },
  };
  await axios
    .get(
      "https://api-lto.zarlab.dev/lotto/results/topLotteries?day=" +
        `${daySelected}`,
      config
    )
    .then((response) => setter(response.data));
};

export const GetDailyLotteries = async (tkn, dayBefore, setter) => {
  const config = {
    headers: { Authorization: `Bearer ${tkn}`, "Accept-Language": "fr-FR" },
  };
  await axios
    .get(
      "https://api-lto.zarlab.dev/lotto/results/countries?day=" +
        `${dayBefore}`,
      config
    )
    .then((response) => setter(response.data));
};

export const GetDailySystems = async (tkn, dayBefore, setter) => {
  const config = {
    headers: { Authorization: `Bearer ${tkn}`, "Accept-Language": "fr-FR" },
  };
  await axios
    .get(
      "https://api-lto.zarlab.dev/lotto/results/systems?day=" + `${dayBefore}`,
      config
    )
    .then((response) => setter(response.data));
};

export const GetDailySublotteries = async (
  tkn,
  dayBefore,
  countryCode,
  setter
) => {
  const config = {
    headers: { Authorization: `Bearer ${tkn}`, "Accept-Language": "fr-FR" },
  };
  await axios
    .get(
      "https://api-lto.zarlab.dev/lotto/results/countries/lotteries?day=" +
        `${dayBefore}` +
        `&countryCode=` +
        `${countryCode}`,
      config
    )
    .then((response) => setter(response.data));
};

export const GetContentByLottery = async (
  tkn,
  dayBefore,
  lotteryId,
  setter
) => {
  const config = {
    headers: { Authorization: `Bearer ${tkn}`, "Accept-Language": "fr-FR" },
  };
  await axios
    .get(
      "https://api-lto.zarlab.dev/lotto/results/lottery/draws?day=" +
        `${dayBefore}` +
        "&lotteryId=" +
        `${lotteryId}`,
      config
    )
    .then((response) => setter(response.data));
};

export const GetContentByCountry = async (
  tkn,
  dayBefore,
  countryCode,
  setter
) => {
  const config = {
    headers: { Authorization: `Bearer ${tkn}`, "Accept-Language": "fr-FR" },
  };
  await axios
    .get(
      "https://api-lto.zarlab.dev/lotto/results/country/draws?day=" +
        `${dayBefore}` +
        "&countryCode=" +
        `${countryCode}`,
      config
    )
    .then((response) => setter(response.data));
};

export const GetContentBySystem = async (dayBefore, systemBuilder, setter) => {
  const config = {
    headers: { Authorization: `Bearer ${tkn}`, "Accept-Language": "fr-FR" },
  };
  await axios
    .get(
      "https://api-lto.zarlab.dev/lotto/results/systems/draws?day=" +
        `${dayBefore}` +
        "&systems=" +
        `${systemBuilder}`,
      config
    )
    .then((response) => setter(response.data));
};

export const VerifyTicketPost = async (tkn, ticketCode, setter) => {
  const config = {
    headers: { Authorization: `Bearer ${tkn}`, "Accept-Language": "fr-FR" },
    headers: { "Access-Control-Allow-Origin": "*" },
  };
  await axios
    .get(
      "https://api-lto.zarlab.dev/lotto/tickets/checkSerial?ticketSerial=" +
        `${ticketCode}`,
      config
    )
    .then((response) => {
      setter(response.data);
    });
};
