import React, { useContext } from "react";
import { AppContext } from "../../App";
import $ from "jquery";
import { GetContentBySystem } from "../../APIManager/APIManager";

const AllButton_Systems = (props) => {
  const consumer = useContext(AppContext);

  const handleCheckChanged = () => {
    GetContentBySystem(
      consumer.token[0],
      consumer.dateSelector[0].indexOf(1),
      "",
      consumer.content[1]
    );
    consumer.buildSystemFilter[1]([]);
    disableAllCheckboxes();
  };

  const disableAllCheckboxes = () => {
    consumer.buildSystemFilterNames[0].forEach((element) => {
      $(`#results${element}`).prop("checked", false);
    });
  };

  return (
    <>
      <input
        id="allLot"
        type="checkbox"
        className="generic_system_item"
        defaultChecked={false}
        onChange={(e) => {
          e.target.checked && handleCheckChanged();
        }}
      />
      <label className="generic_system_item_label">
        <span className="generic_system_item_span">TOUS LES SYSTEMES</span>
      </label>
    </>
  );
};

export default AllButton_Systems;
