import React, { useContext } from "react";
import { AppContext } from "../../App";
import $ from "jquery";
const Generic_Item_Systems = (props) => {
  const consumer = useContext(AppContext);
  const builderConst = consumer.buildSystemFilter[0];
  const builderSetter = consumer.buildSystemFilter[1];
  const allCheckedSetter = consumer.buildSystemFilterNames[1];

  const addSystemToString = (sysValue) => {
    let array = [...builderConst];
    array.push(sysValue);
    builderSetter(array);
    let secondarr = [...consumer.buildSystemFilterNames[0]];

    secondarr.push(sysValue);
    allCheckedSetter(secondarr);
    $("#allLot").prop("checked", false);
  };

  const removeSystemToString = (sysValue) => {
    let array = [...builderConst];
    const index = array.indexOf(sysValue);
    if (index > -1) {
      array.splice(index, 1);
    }
    builderSetter(array);
  };

  return (
    <div className="generic_system_item_root">
      <input
        id={(props.whatFor === "lotto" ? "loto" : "results") + props.element.id}
        type="checkbox"
        className=""
        defaultChecked={false}
        onChange={(e) => {
          e.target.checked
            ? addSystemToString(props.element.id)
            : removeSystemToString(props.element.id);
        }}
      />
      <label className="generic_system_item_label">
        <span className="generic_system_item_span">{props.element.name}</span>
      </label>
    </div>
  );
};

export default Generic_Item_Systems;
