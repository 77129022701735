import React, { useContext } from "react";
import { AppContext } from "../../App";
import { fromStringToArray } from "../../Helpers/Helpers";
import TopIndex_SingleItem from "./TopIndex_SingleItem";

const TopIndexLotteries = (props) => {
  const consumer = useContext(AppContext);
  const topIndex = consumer.topIndex[0];

  return (
    topIndex && (
      <>
        {topIndex.map((element, key) => {
          return (
            <div key={key}>
              <TopIndex_SingleItem
                countryCode={element.imgKey}
                lotteryName={element.name}
                drawBalls={fromStringToArray(element.system, "/")[0]}
                ballsCount={fromStringToArray(element.system, "/")[1]}
                id={element.id}
              />
            </div>
          );
        })}
      </>
    )
  );
};

export default TopIndexLotteries;
