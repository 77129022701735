import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan } from "@fortawesome/fontawesome-free-solid";
import { VerifyTicketPost } from "../../APIManager/APIManager";
import ModalVerify from "./ModalVerify";
import { AppContext } from "../../App";
const Ticket_Verification = (props) => {
  const consumer = useContext(AppContext);
  const [code, setCode] = useState(null);

  const [verifResponse, setVerifResponse] = useState(null);
  const [showVerifModal, setShowVerifModal] = useState(false);

  const handleOpenModal = () => setShowVerifModal(true);
  const handleCloseModal = () => setShowVerifModal(false);

  const handleInputChange = (newVal) => {
    setCode(newVal);
  };

  useEffect(() => {
    console.log(showVerifModal);
  }, [showVerifModal]);

  return (
    <>
      <div className="ticket_verify_root">
        <div className="ticket_verify_right">
          <form>
            <input
              type="text"
              placeholder="Vérifier le billet"
              id="ticketId"
              className="ticket_verify_intup"
              onChange={(e) => {
                handleInputChange(e.target.value, setCode);
              }}
            />
          </form>
          <div
            className="ticket_verify_button"
            onClick={() => {
              VerifyTicketPost(consumer.token[0], code, setVerifResponse);
              handleOpenModal();
              // if (props.mobile) props.HandleMobileVerify();
            }}
          >
            <FontAwesomeIcon icon={faGreaterThan} className="material_icons" />
          </div>
        </div>
      </div>
      {verifResponse != null && (
        <ModalVerify
          response={verifResponse}
          open={showVerifModal}
          onclose={handleCloseModal}
        />
      )}
    </>
  );
};
export default Ticket_Verification;
