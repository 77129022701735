import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { getCurrentDaysArr } from "../../Helpers/Helpers";

const DateSelector = (props) => {
  const consumer = useContext(AppContext);

  const [shortDaysArr, setShortDaysArr] = useState(null);
  useEffect(() => {
    setShortDaysArr(getCurrentDaysArr("FR-fr"));
  }, []);

  const toggleActiveDate = (index) => {
    let aux = [0, 0, 0, 0, 0, 0, 0, 0];
    aux[index] = 1;
    let setter = consumer.dateSelector[1];
    setter(aux);
  };

  const resolveClassName = (index) => {
    if (consumer.dateSelector[0][index] === 0) return "date_selector_button";
    return "date_selector_button date_selector_is_active";
  };

  const generateDay = (index, title) => {
    return (
      <div
        className={resolveClassName(index)}
        onClick={() => {
          toggleActiveDate(index);
        }}
      >
        {title}
      </div>
    );
  };

  const formatDateSelector = (index) => {
    return (
      shortDaysArr && (
        <span style={{ fontWeight: 600 }}>
          {shortDaysArr.shortDays[index]}{" "}
          <span style={{ fontWeight: 300 }}>
            {shortDaysArr.dayNmonth[index].day +
              "." +
              shortDaysArr.dayNmonth[index].month}
          </span>
        </span>
      )
    );
  };

  const mobileClassNameHelper = () => {
    return props.mobile ? "date_selector_root_m" : "date_selector_root";
  };

  return (
    <div className={mobileClassNameHelper()}>
      {generateDay(0, "AUJOURD'HUI")}
      {generateDay(1, formatDateSelector(1))}
      {generateDay(2, formatDateSelector(2))}
      {generateDay(3, formatDateSelector(3))}
      {generateDay(4, formatDateSelector(4))}
      {generateDay(5, formatDateSelector(5))}
      {generateDay(6, formatDateSelector(6))}
    </div>
  );
};

export default DateSelector;
