import React, { useContext, useState } from "react";
import { AppContext } from "../../App";
import AllButton_Lotteries from "./AllButton_Lotteries";
import AllButton_Systems from "./AllButton_Systems";
import Generic_Item from "./Generic_Item";
import Generic_Item_Systems from "./Generic_Item_Systems";
import Generic_MenuList from "./Generic_MenuList";
import Generic_Side_Tabs from "./Generic_Side_Tabs";

const Generic_SideMenu = (props) => {
  const consumer = useContext(AppContext);

  const [countryOpenId, SetCountryOpenId] = useState("home");
  const toggleCountryOpenId = (id) => {
    SetCountryOpenId(id);
  };

  const specificItemForLotteries = (
    key,
    name,
    countryCode,
    openId,
    currentId,
    subElements
  ) => {
    return (
      <Generic_Item
        key={key}
        name={name}
        countryCode={countryCode}
        openId={openId}
        currentId={currentId}
        subElements={subElements}
      />
    );
  };
  const specificItemForSystems = (element) => {
    return <Generic_Item_Systems element={element} />;
  };

  const dynamicMenuChanger = () => {
    let tabSelected = consumer.genericTabs[0].indexOf(1);
    if (tabSelected === 0) {
      return (
        <Generic_MenuList
          allButton={
            <AllButton_Lotteries
              openId={countryOpenId}
              toggleOpenId={toggleCountryOpenId}
            />
          }
          openId={countryOpenId}
          toggleOpenId={toggleCountryOpenId}
          lotteries={consumer.dailyLotteries[0]}
          specificItem={specificItemForLotteries}
        />
      );
    } else if (tabSelected === 1) {
      return (
        <Generic_MenuList
          allButton={<AllButton_Systems />}
          lotteries={consumer.dailySystems[0]}
          specificItem={specificItemForSystems}
        />
      );
    }
  };

  return (
    <div className="generic_sidemenu_root">
      <div className="generic_tabs">
        <Generic_Side_Tabs />
      </div>

      <div className="generic_list">{dynamicMenuChanger()}</div>
    </div>
  );
};

export default Generic_SideMenu;
