import React, { useState } from "react";
import { createFlag } from "../../Helpers/Helpers";
import Generic_SubItem from "./Generic_SubItem";

const Generic_Item = (props) => {
  const [subItemOpenId, setSubItemOpenId] = useState("");
  const toggleSubItemOpenId = (index) => {
    setSubItemOpenId(index);
  };

  return (
    <>
      <div className="all_button_country">
        <div
          className={
            props.openId === props.currentId
              ? "all_button_country_item all_button_country_item_active"
              : "all_button_country_item"
          }
        >
          <img
            className="all_button_icon"
            src={createFlag(props.countryCode)}
            width="18px"
            height="18px"
          />
          <span className="all_button_span">{props.name}</span>
        </div>
        {props.openId === props.currentId
          ? props.subElements &&
            props.subElements.map((element, key) => {
              return (
                <Generic_SubItem
                  element={element}
                  key={key}
                  openId={subItemOpenId}
                  currentId={element.id}
                  toggleOpenId={toggleSubItemOpenId}
                />
              );
            })
          : null}
      </div>
    </>
  );
};

export default Generic_Item;
