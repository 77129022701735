import React, { useContext } from "react";
import { GetContentByCountry } from "../../APIManager/APIManager";
import { AppContext } from "../../App";
import { createFlag } from "../../Helpers/Helpers";

const AllButton_Lotteries = (props) => {
  const consumer = useContext(AppContext);

  const Fetcher = () => {
    GetContentByCountry(
      consumer.token[0],
      consumer.dateSelector[0].indexOf(1),
      "",
      consumer.content[1]
    );
  };

  return (
    <div className="all_button_country">
      <div
        className={
          props.openId === "home"
            ? "all_button_country_item all_button_country_item_active"
            : "all_button_country_item"
        }
        onClick={() => {
          props.toggleOpenId("home");
          Fetcher();
        }}
      >
        <img
          className="all_button_icon"
          src={createFlag("home")}
          width="18px"
          height="18px"
        />
        <span className="all_button_span">TOUTES LES LOTERIES</span>
      </div>
    </div>
  );
};

export default AllButton_Lotteries;
