import React, { useContext } from "react";
import {
  GetContentByCountry,
  GetContentBySystem,
  GetDailySystems,
} from "../../APIManager/APIManager";
import { AppContext } from "../../App";

const Generic_Side_Tabs = (props) => {
  const consumer = useContext(AppContext);

  const toggleActiveTab = (index) => {
    let aux = [0, 0];
    aux[index] = 1;
    let setter = consumer.genericTabs[1];
    setter(aux);
  };
  const resolveClassName = (index) => {
    if (consumer.genericTabs[0][index] === 0) return "generic_side_tab";
    return "generic_side_tab generic_side_tab_active";
  };

  const ttt = () => {
    GetDailySystems(
      consumer.token[0],
      consumer.dateSelector[0].indexOf(1),
      consumer.dailySystems[1]
    );
    GetContentBySystem(
      consumer.token[0],
      consumer.dateSelector[0].indexOf(1),
      "",
      consumer.content[1]
    );
  };

  const Fetcher = (index) => {
    if (index === 0) {
      GetContentByCountry(
        consumer.token[0],
        consumer.dateSelector[0].indexOf(1),
        "",
        consumer.content[1]
      );
    } else if (index === 1) {
      ttt();
    }
  };

  const generateTab = (index, toDisplay) => {
    return (
      <div
        className={resolveClassName(index)}
        onClick={() => {
          toggleActiveTab(index);
          Fetcher(index);
        }}
      >
        {toDisplay}
      </div>
    );
  };

  return (
    <div className="generic_side_tabs">
      {generateTab(0, "LOTTERIES")}
      {generateTab(1, "SYSTEMS")}
    </div>
  );
};

export default Generic_Side_Tabs;
