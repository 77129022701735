import React, { useContext, useState } from "react";
import {
  GetContentByCountry,
  GetDailySublotteries,
} from "../../APIManager/APIManager";
import { AppContext } from "../../App";

const Generic_MenuList = (props) => {
  const consumer = useContext(AppContext);

  const [subItems, setSubItems] = useState(null);

  const getTabIndex = () => {
    if (consumer.genericTabs[0].indexOf(1) === 0) return 0;
    return 1;
  };

  const onClickDone = (toggleOpenId, id, imgkey) => {
    if (getTabIndex() === 0) {
      toggleOpenId(id);
      GetContentByCountry(
        consumer.token[0],
        consumer.dateSelector[0].indexOf(1),
        id,
        consumer.content[1]
      );
      GetDailySublotteries(
        consumer.token[0],
        consumer.dateSelector[0].indexOf(1),
        imgkey,
        setSubItems
      );
    }
  };

  return (
    <div
      className={
        getTabIndex() === 0 ? "generic_list_root" : "generic_list_root_sys"
      }
    >
      <div
        className={
          getTabIndex() === 0
            ? "generic_list_all_button"
            : "generic_list_all_button_sys"
        }
      >
        {props.allButton}
      </div>
      <div
        className={
          getTabIndex() === 0
            ? "generic_list_rest_items"
            : "generic_list_rest_items_sys"
        }
      >
        {props.lotteries &&
          props.lotteries.map((element, key) => {
            if (key > 0)
              return (
                <div
                  onClick={() =>
                    onClickDone(props.toggleOpenId, element.id, element.id)
                  }
                  key={key}
                  className={getTabIndex() === 1 ? "system_flexin_half" : null}
                >
                  {getTabIndex() === 0
                    ? props.specificItem(
                        key,
                        element.name,
                        element.id,
                        props.openId,
                        element.id,
                        subItems,
                        element.liveCast
                      )
                    : props.specificItem(element)}
                </div>
              );
          })}
      </div>
    </div>
  );
};

export default Generic_MenuList;
