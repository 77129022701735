import React from "react";
import Generic_SideMenu from "./Generic_SideMenu";
import Ticket_Verification from "./Ticket_Verification";
import TopIndexLotteries from "./TopIndexLotteries";

const SideMenu = (props) => {
  return (
    <div className="side_menu_root">
      {!props.mobile && (
        <div className="side_menu_verify_ticket">
          <Ticket_Verification />
        </div>
      )}
      <div className="side_menu_top_index">
        <TopIndexLotteries />
      </div>
      <div className="side_menu_generic">
        <Generic_SideMenu />
      </div>
    </div>
  );
};

export default SideMenu;
