import React, { useContext } from "react";
import { GetContentByLottery } from "../../APIManager/APIManager";
import { AppContext } from "../../App";
import { fromStringToArray } from "../../Helpers/Helpers";

const Generic_SubItem = (props) => {
  const consumer = useContext(AppContext);

  const Fetcher = () => {
    GetContentByLottery(
      consumer.token[0],
      consumer.dateSelector[0].indexOf(1),
      props.currentId,
      consumer.content[1]
    );
  };

  return (
    <div
      className={
        props.openId === props.currentId
          ? "generic_country_subitem subitem_active"
          : "generic_country_subitem"
      }
      onClick={(e) => {
        props.toggleOpenId(props.element.id);
        e.stopPropagation();
        Fetcher();
      }}
    >
      <div className="generic_subitem_spaning">
        {props.element.name}-{fromStringToArray(props.element.system, "/")[0]}/
        {fromStringToArray(props.element.system, "/")[1]}
      </div>
    </div>
  );
};

export default Generic_SubItem;
