import React, { useContext, useEffect } from "react";
import { AppContext } from "../../App";
import InfiniteScroll from "react-infinite-scroll-component";
import Content_SingleRow from "./Content_SingleRow";
const Content_List = () => {
  const consumer = useContext(AppContext);

  useEffect(() => {
    consumer.initMoreData();
  }, [consumer.content[0]]);

  return (
    <>
      {consumer.current[0] != null && (
        <InfiniteScroll
          dataLength={consumer.current[0].length}
          next={consumer.getMoreData}
          hasMore={consumer.hasMore[0]}
        >
          <div>
            {consumer.current[0] &&
              consumer.current[0].map((element, key) => {
                return (
                  <div key={key}>
                    <Content_SingleRow lottery={element} />
                  </div>
                );
              })}
          </div>
        </InfiniteScroll>
      )}
      {/* {consumer.current[0] &&
        consumer.current[0].map((element, key) => {
          return <div key={key}>xx</div>;
        })} */}
    </>
  );
};

export default Content_List;
